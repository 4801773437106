<template>
  <div v-if="displayModal" class="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
    <div class="bg-white rounded-md p-6 bottom-0 fixed w-full" style="max-width: 500px; position: fixed;">
      <h2 class="text-lg font-medium mb-4">{{title}}</h2>
      <p class="text-sm text-gray-700 mb-4">{{message}}</p>
      <span class="text-xs tracking-tighter leading-snug font-thin text-gray-400">Together Gift It is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to products on Amazon.com</span>
      <div class="flex justify-end">
        <button class="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 mr-2" @click="onDismiss">{{ dismissText }}</button>
        <button class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600" @click="onConsent">{{ consentText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import cookieService from '@/cookieService';

export default {
  name: 'GdprConsent',

  props: {
    title: {
      type: String,
      default: 'Privacy Settings'
    },
    message: {
      type: String,
      default: 'We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.'
    },
    dismissText: {
      type: String,
      default: 'Decline'
    },
    consentText: {
      type: String,
      default: 'Accept'
    },
  },

  data() {
    return {
      displayModal: false,
    };
  },

  methods: {
    onDismiss() {
      cookieService.setConsentCookie(false);
      this.displayModal = false;
    },

    onConsent() {
      cookieService.setConsentCookie(true);
      this.displayModal = false;
      this.$gtm.enable(true);
    }
  },

  created() {
    // Check for the consent cookie
    const consentCookie = cookieService.getConsentCookie();
    if (consentCookie === undefined) {
      this.displayModal = true;
    }
  },
};
</script>
