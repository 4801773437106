<template>
  <header class="border-b border-gray-400">
    <div class="mx-auto flex items-center">
      <HamburgerNav class="sm:hidden block" :currentUser="currentUser" :navigation="navigation" :topNavigation="topNavigation" />
      <router-link class="nav mx-auto sm:mx-0" :to="homeLink">
        <img class="sm:w-64 max-h-16 sm:max-h-64 mt-4 sm:mt-0" src="./default-layout/together-gift-it-logo2.png" alt="Together Gift It" />
      </router-link>
      <div class="sm:ml-auto flex pt-8">
        <router-link class="hidden sm:block nav" v-for="item in topNavigation" :key="item.name" :class="[item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex items-center px-2 py-2 text-lg font-medium']" :aria-current="item.current ? 'page' : undefined" :to="item.routerLink">
          <span class="py-2 text-gray-700 text-sm font-medium">{{ item.name }}</span>
        </router-link>
        <LoginModal v-if="!currentUser" :api-url="apiUrl">
          <button type="button" class="hidden sm:block mr-4 ml-4 font-bold tracking-wide uppercase inline-flex bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50">
            Get Started
          </button>
          <p class="sm:hidden block cursor-pointer uppercase text-xs text-gray-600 pt-1 pr-2">
            Login            
          </p>  
        </LoginModal>
        <LoginModal v-if="!currentUser" :api-url="apiUrl">
          <svg class="sm:hidden block w-6 h-6 cursor-pointer text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </LoginModal>
        <div v-if="currentUser" class="hidden sm:flex grow text-sm font-medium text-gray-400 items-center pr-4">          
          <a class="ml-4 logout-container " v-bind:href="logoutUrl">
            Logout
            <svg class="w-5 h-5 pl-1 pr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
            </svg>            
          </a>        
        </div>        
      </div>
    </div>
  </header>
</template>

<script>
import LoginModal from './modals/LoginModal.vue'
import HamburgerNav from './HamburgerNav.vue'
import store from '@/store'

export default {
  props: {
    currentUser: Object,
  },
  components: {
    LoginModal,
    HamburgerNav,
  },
  computed: {
    homeLink () {
      return this.currentUser ? '/app.html' : '/'
    },
  },
  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL.replace('api/', ''),
      logoutUrl: process.env.VUE_APP_API_URL + 'auth/logout',
      topNavigation: [],
      navigation: [],
    }
  },
  mounted() {
    this.topNavigation = [
      { name: 'About Us', routerLink: '/about.html', current: this.$route.path.includes("/about") },
      { name: 'Blog', routerLink: '/blog.html', current: this.$route.path.includes("/blog") },
    ]
    if (this.currentUser) {
      this.navigation = store.navigationItems(this.$route)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logout-container {
  display: flex; /* Ensure flex layout */
  align-items: center; /* Align items vertically */
  white-space: nowrap; /* Prevent wrapping of text */
}
</style>
