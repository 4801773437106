<template>
  <div class="border-b border-gray-200">
    <nav class="ml-4 -mb-px flex space-x-4 sm:space-x-8" aria-label="Tabs">
      <p v-for="tab in tabs" @click="selectTab(tab)" v-bind:key="tab.name" :class="[tab.current ? 'font-extrabold text-pink-500' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 sm:px-0 border-b-2 font-medium cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">{{tab.name}}</p>
    </nav>
  </div>

  <div v-if="lastMessage" class="border-b border-gray-200">
    <div class="my-2 mx-4 text-gray-500 italic inline-block p-0 m-0">
      <SpinnerConnect v-if="connectionStatus != 'Disconnected'" />
      {{ lastMessage }}
    </div>
  </div>
  
  <div class="p-4 rounded">
    <div id="messagePane" v-if="currentView == 'log'" class="space-y-4">
      <div v-for="(message, index) in messages" :key="index" class="p-3 bg-white rounded shadow">
        <p class="text-gray-700">{{ message }}</p>
      </div>
    </div>
    <div v-if="currentView == 'search-results'">
      <SpinnerProgress v-if="!this.addedToCart?.length" />

      <ProductRecommendationModal :starAllowed="prefsAllowed" v-for="product in addedToCart" :key="product.id" :product="product" :products="this.addedToCart" :productSearchSlug="product.searchSlug" @product-star-change="fetchSearchData">
        <ProductThumbnail :product="product" />
      </ProductRecommendationModal>
    </div>
  </div>
</template>

<script>
import humps from 'humps'
import ProductThumbnail from '@/components/ProductThumbnail.vue'
import ProductRecommendationModal from '@/components/modals/ProductRecommendationModal.vue'
import SpinnerProgress from '@/components/SpinnerProgress.vue'
import SpinnerConnect from './SpinnerConnect.vue'
import api from '@/api'

export default {
  components: {
    ProductThumbnail,
    ProductRecommendationModal,
    SpinnerProgress,
    SpinnerConnect,
  },
  emits: ['searchStatusUpdate'],

  props: {
    productSearchSlug: String,
    prefsAllowed: Boolean,
  },

  async mounted() {
    this.selectTab(this.tabs[0])
    this.connectionStatus = "Disconnected"
    this.eventSource = new EventSource(`${process.env.VUE_APP_API_URL}events/search/${this.productSearchSlug}`, { withCredentials: true })
    this.connectionStatus = "Connected"
    this.eventSource.addEventListener('productSearch', event => {
      const data = JSON.parse(event.data)
      const camelCaseData = humps.camelizeKeys(JSON.parse(data.data))
      if (camelCaseData.message) {
        this.lastMessage = camelCaseData.message
        this.messages.push(camelCaseData.message)
      }
      if (camelCaseData.addedToCart) {
        this.addedToCart = this.addedToCart.concat(camelCaseData.addedToCart)
      }
      if (camelCaseData.status) {
        if (camelCaseData.status == "Complete") {
          this.connectionStatus = "Disconnected"
          this.eventSource.close()
        }
        this.$emit('searchStatusUpdate', { status: camelCaseData.status })
      }
    })
  },

  unmounted() {
    this.eventSource.close()
  },

  data () {
    return {
      eventSource: null,
      messages: [],
      addedToCart: [],
      tabs: [{ name: "Searching Results", viewName: "search-results" }, { name: "Log", viewName: "log" }],
      currentView: String,
      lastMessage: "Connecting...",
      connectionStatus: String,
    }
  },

  methods: {
    async fetchSearchData() {
      const response = await api.getSearch([this.productSearchSlug])
      if (response.success) {
        this.addedToCart = response.data.productRecommendations
      }
    },

    selectTab(tab) {
      this.currentView = tab.viewName
      this.tabs.forEach(t => {
        t.current = t.name == tab.name
      })
    }
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#messagePane::-webkit-scrollbar {
  width: 8px;
}

#messagePane::-webkit-scrollbar-thumb {
  background-color: #cbd5e0; /* Tailwind's gray-400 */
  border-radius: 4px;
}

#messagePane::-webkit-scrollbar-thumb:hover {
  background-color: #a0aec0; /* Tailwind's gray-500 */
}
</style>
