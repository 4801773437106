<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <h1>{{ currentProduct.name }}</h1>
              <div class="mt-2 relative">
                <div class="flex justify-center items-center mx-10">
                  <a class="inline-block focus:outline-none" v-if="currentProduct.url && (currentProduct.url.startsWith('http://') || currentProduct.url.startsWith('https://'))" :href="currentProduct.url" target="_blank">
                    <img class="focus:outline-none max-w-full h-48 object-contain sm:h-64" :src="currentProduct.imageUrl || currentProduct.mainImageUrl" alt="Product Image">
                  </a>
                  <img class="max-w-full h-48 object-contain sm:h-64" :src="currentProduct.imageUrl" v-else alt="Product Image" />
                </div>
                <!-- Left Arrow -->
                <div v-if="hasPrevious" class="absolute left-2 top-1/4 transform -translate-y-1/2 cursor-pointer" @click="navigateProducts('prev')">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="rgba(0, 0, 0, 0.5)"/>
                  </svg>
                </div>
                <!-- Right Arrow -->
                <div v-if="hasNext" class="absolute right-2 top-1/4 transform -translate-y-1/2 cursor-pointer" @click="navigateProducts('next')">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-10 h-10">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="rgba(0, 0, 0, 0.5)"/>
                  </svg>
                </div>
                <div class="flex justify-between items-center">
                  <div>
                    <div class="text-base text-gray-500">{{ formatPrice(currentProduct.price) }}</div>
                    <div class="flex text-gray-400">
                      <svg
                        v-for="star in 5"
                        :key="star"
                        class="w-4 h-4"
                        :class="{'text-pink-200': star <= currentProduct.rating, 'text-gray-200': star > currentProduct.rating}"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 .288l2.833 8.718H24l-7.08 5.145 2.832 8.719L12 17.712l-7.08 5.158 2.832-8.719L0 9.006h9.167z" />
                      </svg>
                    </div>
                  </div>
                  <div class="mr-8" v-if="starAllowed">
                    <StarProduct :product="currentProduct" :productSearch="currentProduct.searchSlug" @product-star-change="handleProductStarChange" />
                  </div>
                </div>
                <p class="mt-2 text-gray-500 italic">{{ currentProduct.description }}</p>
                <div v-if="currentProduct.url && (currentProduct.url.startsWith('http://') || currentProduct.url.startsWith('https://'))" class="mt-2 flex items-center space-x-2">
                  <a :href="currentProduct.url" target="_blank" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-300 hover:bg-blue-500 focus:outline-none active:bg-blue-500 transition ease-in-out duration-150">
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M12.293 10.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L15.586 17H5a3 3 0 01-3-3V5a3 3 0 013-3h11a3 3 0 013 3v5a1 1 0 11-2 0V5a1 1 0 00-1-1H5a1 1 0 00-1 1v9a1 1 0 001 1h10.586l-2.293-2.293a1 1 0 010-1.414z" />
                    </svg>
                    <span class="ml-2">Visit</span>
                  </a>
                </div>
              </div>
            
              <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <a class="inline-block cursor-pointer pr-2 pb-3" @click="openModal()">
    <slot />
  </a>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline'
import { 
  Dialog, 
  DialogPanel, 
  TransitionChild, 
  TransitionRoot,
} from '@headlessui/vue'
import StarProduct from '@/components/StarProduct.vue'

export default {
  components: {
    Dialog, 
    DialogPanel, 
    TransitionChild, 
    TransitionRoot,
    XIcon,
    StarProduct
  },
  data() {
    return {
      open: false,
      currentIndex: 0,
    }
  },
  emits: ['product-star-change'],
  props: {
    product: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => []
    },
    productSearchSlug: {
      type: String,
      default: ''
    },
    starAllowed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    allProducts() {
      return this.products.filter(p => !p.trash)
    },
    currentProduct() {
      return this.allProducts[this.currentIndex];
    },
    hasPrevious() {
      return this.currentIndex > 0;
    },
    hasNext() {
      return this.currentIndex < this.allProducts.length - 1;
    },
  },
  methods: {
    async openModal() {
      this.currentIndex = this.allProducts.findIndex(p => p === this.product)
      this.open = true
    },
    formatPrice(price) {
      let parsedPrice = parseFloat(price);
      if (!isNaN(parsedPrice) && parsedPrice > 0) {
        return '$' + parsedPrice.toFixed(2);
      }
      return ' ';
    },
    navigateProducts(direction) {
      if (direction === 'next' && this.hasNext) {
        this.currentIndex++;
      } else if (direction === 'prev' && this.hasPrevious) {
        this.currentIndex--;
      }
    },
    handleProductStarChange(product) {
      this.$emit('product-star-change', product);
    }
  },
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
