<template>
  <footer class="bg-pink-500 text-white py-4" aria-labelledby="footer-heading">
    
    <p class="pl-2 inline">
      &copy; 2024 My Day Off, LLC. All rights reserved.      
    </p>

    <p class="sm:float-left sm:py-4 md:float-right md:py-0 ml-4">
      <a href="/privacy.html">Privacy Policy</a>
      <a href="/terms.html" class="mx-4">Terms of Service</a>
    </p>
  </footer>
</template>

<script>

export default {
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
