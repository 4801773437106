import axios from 'axios'
import store from './store'
import humps from 'humps'

export default {

  async getGiftEvents() {
    try {
      const response = await this.get(`gift_events`)
      return({ success: true, giftEvents: response.data?.giftEvents })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async getGiftBotQuestions(giftRecipientSlug, answered = true) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}recipient_questions/${giftRecipientSlug}?answered=${answered}`, { withCredentials: true })
      return({ success: true, ...humps.camelizeKeys(response.data) })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteQuestion(question) {
    try {
      const response = await this.delete(`recipient_questions/${question.id}`)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async submitQuestionAnswer(questionId, answer, isSelected) {
    try {
      const payload = humps.decamelizeKeys({
        questionId,
        answer,
        isSelected
      })
      const response = await this.post(`gift_bot/answers`, payload)
      const responseData = response.success ? response.data : response.error.response.data
      return({ success: true, data: responseData })
    } catch (err) {
      return({ success: false, data: err })
    }
  },
  
  async completeTask(slug, data) {
    try {
      const path = `tasks/update/${slug}`
      const payload = humps.decamelizeKeys(data)
      const response = await this.post(path, payload)
      const response_data = response.success ? response.data : response.error.response.data
      return({ success: response.success, data: response_data })
    } catch (err) {
      return({ success: false, data: err })
    }
  },

  async getGiftListRecipients() {
    try {
      const response = await this.get(`gift_lists`)
      return({ 
        success: true, 
        ...response.data,
      });
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async shareList (email, recipientId) {
    const response = await axios.post('/api/share-list', { email, recipientId })
    return response.data
  },
  
  async getTasks(giftEventSlug) {
    try {
      const response = await this.get(`tasks/${giftEventSlug}`)
      return({ 
        success: true, 
        ...response.data,
      });
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getGiftEventPurchases(giftEventSlug) {
    try {
      const response = await this.get(`gift_events/${giftEventSlug}/purchases/`)
      return({ 
        success: true,
        purchases: response.data.purchases,
      });
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getGiftEvent(slug) {
    try {
      const response = await this.get(`gift_events/${slug}`)
      return({ 
        success: true, 
        ...response.data,        
      })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteGiftInvite(slug, inviteId) {
    try {
      const response = await this.delete(`gift_events/${slug}/invites/${inviteId}`)
      return({ success: true, invites: response.data?.invites })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteGiftEventSuggestion(suggestionId) {
    try {
      const response = await this.delete(`gift_suggestions/${suggestionId}`)
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteGiftEventUser(slug, userId) {
    try {
      const response = await this.delete(`gift_events/${slug}/users/${userId}`)
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async updateRecipient(giftEventSlug, recipientSlug, data) {
    const payload = humps.decamelizeKeys(data)
    try {
      const path = `gift_events/${giftEventSlug}/recipients/${recipientSlug}`
      const response = await this.post(path, payload)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async updateGiftListRecipient(recipientSlug, data) {
    const payload = humps.decamelizeKeys(data)
    try {
      const path = `gift_lists/recipients/update/${recipientSlug}`
      const response = await this.post(path, payload)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteGiftListRecipient(recipientSlug) {
    try {
      const path = `gift_lists/recipients/destroy/${recipientSlug}`
      const response = await this.post(path, {})
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async resendGiftInvite(slug, userId) {
    try {
      const path = `gift_events/${slug}/resend-invite/${userId}`
      const response = await this.post(path, { invite_slug: userId })
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getGiftEventInvites(slug) {
    try {
      const response = await this.get(`gift_events/${slug}/invites`)
      return({ success: true, users: response.data?.users, currentUserRoles: response.data?.currentUserRoles })
    } catch (err) {
      return({ success: false, error: err })
    }
  },
 
  async getGiftEventUsers(giftEventId) {
    try {
      const response = await this.get(`gift_events/${giftEventId}/users`)
      return({ success: true, users: response.data?.users })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getGiftEventSuggestions(giftEventSlug, recipientSlug) {
    try {
      const response = await this.get(`gift_events/${giftEventSlug}/suggestions/${recipientSlug}`)
      return({ success: true, ... response.data })
    } catch (err) {
      return({ success: false, error: err })
    } 
  },

  async getGiftListSuggestions(userSlug) {
    try {
      const response = await this.get(`gift_lists/suggestions/${userSlug}`)
      return({ success: true, ...response.data })
    } catch (err) {
      return({ success: false, error: err })
    } 
  },

  async createUser(loginRequestSlug, userData) { 
    try {
      const response = await this.post(`auth/login_requests/${loginRequestSlug}`, userData)
      return({ success: true, data: humps.camelizeKeys(response.data) })
    } catch (err) {
      return({ success: false, error: err })
    }      
  },

  async createComment(commentData) { 
    try {
      const response = await this.post(`gift_events/${commentData.giftEventSlug}/recipients/${commentData.giftRecipientSlug}/comment`, { comment: { message: commentData.message }})
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }      
  },

  async getComments(giftEventSlug, giftRecipientSlug) {
    try {
      const response = await this.get(`gift_events/${giftEventSlug}/recipients/${giftRecipientSlug}/comments`)
      return({ 
        success: true, 
        comments: response.data?.comments,
        currentUserRoles: response.data?.currentUserRoles
      })
    } catch (err) {
      return({ success: false, error: err })
    } 
  },

  async deleteComment(giftEventSlug, giftRecipientSlug, commentSlug) {
    return await this.delete(`gift_events/${giftEventSlug}/recipients/${giftRecipientSlug}/comments/${commentSlug}`)
  },

  async deleteGiftRecipient(giftEventSlug, giftRecipientSlug) {
    return await this.delete(`gift_events/${giftEventSlug}/recipients/${giftRecipientSlug}`)
  },

  async deleteShareList(recipientListShareSlug) {
    return await this.delete(`share_list/${recipientListShareSlug}`)
  },

  async getShareList(slug) {
    try {
      const response = await this.get(`share_list/${slug}`)
      return({ 
        success: true, 
        data: humps.camelizeKeys(response.data.data),
      });
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getGiftEventRecipients(slug) {
    try {
      const response = await this.get(`gift_events/${slug}/recipients`)
      return({ 
        ...response.data,
        success: true, 
      })
    } catch (err) {
      return({ success: false, error: err })
    } 
  },

  async createInvite(slug, inviteData) {
    try {
      const payload = humps.decamelizeKeys(inviteData)
      const response = await this.post(`gift_events/${slug}/invite`, payload)
      return(response.data)    
    } catch (err) {
      return({ success: false, message: err })
    }
  },

  async shareRecipientList(recipientSlug, payload) {
    let response
    try {
      response = await this.post(`/gift_lists/share/${recipientSlug}`, humps.decamelizeKeys(payload))
      if (response.success) {
      return({ success: true, response: response, data: response.data })
      } else {
        return ({ success: false, response: response.error?.response, data: response.error?.response.data })
      }
    } catch (err) {
      return({ success: false, error: err, response: response })
    }
  },

  async createLoginRequest(email) {
    try {
      const response = await this.post(`auth/login_requests`, { email: email })
      return(response.data)    
    } catch (err) {
      return({ success: false, message: err })
    }
  },

  async createGiftAttribution(giftSuggestionSlug, giftAttribution) {
    try {
      const payload = humps.decamelizeKeys(giftAttribution)
      const response = await this.post(`gift_suggestions/${giftSuggestionSlug}/gift_attributions`, { gift_attribution: payload })
      return(response.data)    
    } catch (err) {
      return({ success: false, message: err })
    }
  },

  async deleteGiftAttribution(giftSuggestionSlug, giftAttributionId) {
    try {
      const response = await this.post(`gift_suggestions/${giftSuggestionSlug}/gift_attributions/delete/${giftAttributionId}`, { })
      return(response.data)    
    } catch (err) {
      return({ success: false, message: err })
    }
  },

  async createGiftEvent(giftEvent) {
    try {
      const response = await this.post(`gift_events`, giftEvent)
      return({ success: true, gift_event: response.data?.gift_event })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async createGiftSuggestion(giftEventSlug, suggestion) {
    try {
      const response = await this.post(`gift_events/${giftEventSlug}/suggestion`, { suggestion: humps.decamelizeKeys(suggestion) })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async createGiftListFromGiftBotSuggestion(giftRecipientSlug, giftBotSuggestion) {
    try {
      const response = await this.post(`gift_bot/promote_gift_bot_suggestion/${giftRecipientSlug}`, { gift_bot_suggestion: humps.decamelizeKeys(giftBotSuggestion) })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async createGiftListGiftSuggestion(suggestion) {
    try {
      const response = await this.post(`gift_lists/suggestion`, { suggestion: humps.decamelizeKeys(suggestion) })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async updateGiftSuggestion(giftEventSlug, giftSuggestionSlug, payload) {
    try {
      const response = await this.post(`gift_events/${giftEventSlug}/suggestion/${giftSuggestionSlug}`, { suggestion: humps.decamelizeKeys(payload) })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async updateGiftListGiftSuggestion(giftSuggestionSlug, payload) {
    try {
      const response = await this.post(`gift_lists/suggestion/${giftSuggestionSlug}`, { suggestion: humps.decamelizeKeys(payload) })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async updateGiftEvent(giftEventSlug, payload) {
    try {
      const response = await this.post(`gift_events/${giftEventSlug}`, humps.decamelizeKeys(payload))
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async deleteGiftEvent(giftEventSlug) {
    return await this.delete(`gift_events/${giftEventSlug}`)
  },

  async post(path, payload) {
    try {
      const token = store.getBearerToken()
      const options = { 
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` }
      }
      payload['authenticity_token'] = store.getFormAuthenticityToken()
      const response = await axios.post(`${process.env.VUE_APP_API_URL}${path}`, 
                                            payload, 
                                            options)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async put(path, payload) {
    try {
      const token = store.getBearerToken()
      const options = { 
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` }
      }
      payload['authenticity_token'] = store.getFormAuthenticityToken()
      const response = await axios.put(`${process.env.VUE_APP_API_URL}${path}`, 
                                            payload, 
                                            options)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async delete(path, payload = {}) {
    try {
      const token = store.getBearerToken()
      const response = await axios.delete(`${process.env.VUE_APP_API_URL}${path}`, {
        headers: { 'Authorization': `Bearer ${token}` },
        data: payload
      })
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async get(path) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}${path}`, { withCredentials: true })
      return({ success: true, data: humps.camelizeKeys(response.data) })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async getGiftBotSuggestions(giftRecipientSlug) {
    try {
      const response = await this.get(`gift_bot/${giftRecipientSlug}`)
      return({ success: true, ... humps.camelizeKeys(response.data)  })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async rateGiftBotSuggestion(giftBotSuggestionId, ratingType) {
    try {
      const payload = humps.decamelizeKeys({ ratingType, giftBotSuggestionId })
      const response = await this.put(`gift_bot/rate`, payload)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async deleteGiftBotSuggestion(giftBotSuggestionId) {
    console.log(giftBotSuggestionId)
    try {
      const response = await this.delete(`gift_bot/${giftBotSuggestionId}`)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async startGiftBot(giftRecipientSlug, data) {
    const payload = humps.decamelizeKeys({ giftRecipientSlug, ...data })
    try {
      const response = await this.post(`gift_bot/start`, payload)
      return({ success: true, data: response.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async startProductAgent(data) {
    const payload = humps.decamelizeKeys({ productSearchSlug: data.productSearchSlug, giftSuggestionSlug: data.giftSuggestionSlug, searchOptions: data.searchOptions })
    try {
      const response = await this.post(`gift_bot/start_product_agent`, payload)
      return({ success: true, data: humps.camelizeKeys(response.data) })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async updateProductRecommendationPref(productSearchSlug, productSlug, payload) {
    try {
      const response = await this.put(`product_recommendations/${productSearchSlug}/${productSlug}`, { preference: payload })
      return({ success: true, response: response })
    } catch (err) {
      return({ success: false, error: err })
    }    
  },

  async submitGiftSuggestion(data) {
    try {
      const payload = humps.decamelizeKeys(data)
      const response = await this.post(`gift_suggestions`, { gift_suggestion: payload })
      return({ success: true, data: humps.camelizeKeys(response.data) })
    } catch (err) {
      return({ success: false, error: err })
    }
  },

  async getSearch(productSearchSlugs) {
    try {
      const response = await this.get(`/product_searches?slugs=${productSearchSlugs.map(slug => encodeURIComponent(slug)).join(',')}`)
      return({ success: true, data: response.data.data })
    } catch (err) {
      return({ success: false, error: err })
    }
  },
}
