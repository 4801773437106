const cookieService = {
  getConsentCookie() {
    const name = 'consentCookie=';
    const cookies = document.cookie.split(';');
    for(let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return undefined;
  },

  setConsentCookie(value) {
    const date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 2 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = "consentCookie=" + value + ";" + expires + ";path=/";
  }
};

export default cookieService;
