<template>
  <div v-if="navigation || topNavigation" class="pt-6 px-4">
    <!-- Hamburger button -->
    <button @click="open = !open" class="hamburger-button relative z-10">
      <div v-if="!open" class="block">
        <!-- Hamburger icon -->
        <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </div>
      <div v-if="open" class="block">
        <!-- Close icon -->
        <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
    </button>

    <!-- Overlay -->
    <div v-if="open" @click="open = false" class="overlay fixed inset-0 bg-gray-800 bg-opacity-80 z-0">
      <div class="bg-white border-solid-1px rounded-md px-16 py-16">
        <img class="w-64 pb-10" src="./default-layout/together-gift-it-logo2.png" alt="Together Gift It" />
        <nav class="flex flex-col items-center">
          <router-link v-for="item in navigation" :key="item.name" :class="[item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex items-center px-2 py-2 text-lg font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined" :to="item.routerLink">          
            <span class="py-2">{{ item.name }}</span>
          </router-link>
          <hr v-if="navigation" class="border-1 border-gray-200 my-4 w-full">
          <router-link v-for="item in topNavigation" :key="item.name" :class="[item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex items-center px-2 py-2 text-lg font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined" :to="item.routerLink">          
            <span class="py-2">{{ item.name }}</span>
          </router-link>
          <hr class="border-1 border-gray-200 my-4 w-full">
          <a v-if="currentUser" v-bind:href="logoutUrl" class="flex grow pt-4 text-gray-700 hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm">
            Logout
            <svg class="w-5 h-5 pl-1 pr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigation: Array,
    currentUser: Object,
    topNavigation: Array,
  },
  data() {
    return {
      open: false,  // State for overlay visibility
      logoutUrl: process.env.VUE_APP_API_URL + 'auth/logout'
    };
  },
};
</script>

<style scoped>
.hamburger-button {
  @apply p-2 rounded-md text-gray-700 bg-gray-100;
}

.overlay {
  @apply flex justify-center items-center;
}
</style>
