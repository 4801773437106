<template>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <h2 class="text-2xl font-semibold text-pink-500 text-center mx-auto mb-4">Product Search</h2>
              <img class="mx-auto" style="max-width: 150px;" src="/together-gift-bot.webp" alt="Together GiftBot" />

              <div id="startFormContainer" v-if="wizardStep=='start'">
                <form @submit.prevent="submitGiftSuggestion">
                  <div class="mb-4">
                    <label for="giftSuggestion" class="block text-md py-4 font-medium text-lg text-gray-800 text-center">What's your gift idea?</label>
                    <input type="text" id="giftSuggestion" v-model="giftSuggestionInput" class="mt-1 focus:ring-pink-500 focus:border-pink-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" autofocus required />
                  </div>
                  <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-500 text-base font-medium text-white hover:bg-pink-600 sm:text-sm">NEXT</button>
                </form>
                <div v-if="errorMessage" class="mt-4 bg-red-50 border-l-4 border-red-400 p-4">
                  <p class="text-sm text-red-700">{{ errorMessage }}</p>
                </div>
              </div>

              <StartProductAgent v-if="wizardStep=='select'" @activateGiftBot="handleActivateGiftBot" />

              <div v-if="wizardStep=='searching'">
                <ProductSearchInProgress :prefsAllowed="false" :productSearchSlug="productSearchSlug" />
              </div>

              <div v-if="wizardStep=='limitReached'" class="mt-4 bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <p class="text-sm text-yellow-700">You have reached the limit of 3 free submissions. Please sign up to continue using our service.</p>
                <LoginModal>
                  <button type="button" class="font-bold tracking-wide uppercase inline-flex bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-opacity-50">
                    Get Started Now
                  </button>
                </LoginModal>
              </div>

              <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <div class="inline" @click="openModal()">
    <slot />
  </div>

</template>

<script>
import { XIcon } from '@heroicons/vue/outline'
import StartProductAgent from '@/components/StartProductAgent.vue'
import { 
  Dialog, 
  DialogPanel, 
  TransitionChild, 
  TransitionRoot,
} from '@headlessui/vue'
import api from '@/api'
import ProductSearchInProgress from '../ProductSearchInProgress.vue'
import LoginModal from '@/components/modals/LoginModal.vue'

export default {
  components: {
    Dialog, 
    DialogPanel, 
    TransitionChild, 
    TransitionRoot,
    XIcon,
    StartProductAgent,
    ProductSearchInProgress,
    LoginModal,
  },
  data () {
    return {
      open: false,
      wizardStep: null,
      productSearchSlug: null,
      giftSuggestionInput: '',
      errorMessage: null,
      submissionCount: 0,
    }
  },
  props: {
    giftSuggestion: Object,
    giftRecipientSlug: String,
    searchStatus: String,
    searchSlug: String,
  },

  async mounted() {
    this.submissionCount = this.getSubmissionCount()
  },
  
  methods: {
    async openModal() {
      this.wizardStep = "start"
      this.open = true
    },

    async handleActivateGiftBot(payload) {
      const response = await api.startProductAgent({ giftSuggestionSlug: this.giftSuggestionSlug, searchOptions: payload })
      if (response.success) {
        this.wizardStep = "searching"
        this.productSearchSlug = response.data.productSearchSlug
      } else {
        alert("There was a problem with your request. Please try again.")
      }
    },

    async submitGiftSuggestion() {
      if (this.submissionCount >= 3) {
        this.wizardStep = 'limitReached'
        return
      }

      try {
        const response = await api.submitGiftSuggestion({ name: this.giftSuggestionInput })
        if (response.success) {
          this.giftSuggestionSlug = response.data.giftSuggestionSlug
          this.wizardStep = 'select'
          this.errorMessage = null
          this.incrementSubmissionCount()
        } else {
          this.errorMessage = response.error.response.data.errors.join(', ')
        }
      } catch (error) {
        this.errorMessage = 'An unexpected error occurred. Please try again later.'
      }
    },

    incrementSubmissionCount() {
      this.submissionCount += 1
      localStorage.setItem('submissionCount', this.submissionCount)
      document.cookie = `submissionCount=${this.submissionCount}; path=/; max-age=${60 * 60 * 24 * 365 * 10}`
    },

    getSubmissionCount() {
      const fromLocalStorage = localStorage.getItem('submissionCount')
      const fromCookie = document.cookie.split('; ').find(row => row.startsWith('submissionCount='))?.split('=')[1]
      return parseInt(fromLocalStorage || fromCookie || '0', 10)
    },
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
