<template>
  <div class="flex flex-col items-center relative">
    <img :src="product.imageUrl || product.mainImageUrl" class="w-16 pb-1" />

    <!-- star svg -->
    <svg
      v-if="product.star"
      fill="gold"
      stroke="gray"
      stroke-width="1"
      class="w-4 h-6 absolute bottom-8 right-1"
      viewBox="0 0 24 24"
    >
      <path d="M12 .288l2.833 8.718H24l-7.08 5.145 2.832 8.719L12 17.712l-7.08 5.158 2.832-8.719L0 9.006h9.167z" />
    </svg>
    <!-- end star svg -->

    <div class="ml-2">
      <span class="text-xs text-gray-500">{{ formatPrice(product.price) }}</span>
      <div v-if="product.rating" class="mt-1">
        <div class="flex items-center">
          <div class="relative">
            <div class="flex text-gray-400">
              <svg
                v-for="star in 5"
                :key="star"
                class="w-4 h-4"
                :class="{'text-pink-200': star <= product.rating, 'text-gray-200': star > product.rating}"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 .288l2.833 8.718H24l-7.08 5.145 2.832 8.719L12 17.712l-7.08 5.158 2.832-8.719L0 9.006h9.167z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductThumbnail',
  props: {
    product: Object
  },
  methods: {
    formatPrice(price) {
      let parsedPrice = parseFloat(price);
      if (!isNaN(parsedPrice) && parsedPrice > 0) {
        return '$' + parsedPrice.toFixed(2);
      }
      return ' ';
    }
  }
}
</script>

<style scoped>
</style>
