import api from './api'

let currentUser = null
let formAuthenticityToken = null

export default {

  getBearerToken() {
    return localStorage.getItem('bearer_token');
  },

  setBearerToken(value) {
    localStorage.setItem('bearer_token', value);
  },

  getAuthType() {
    return localStorage.getItem('auth_type');
  },

  setAuthType(value) {
    localStorage.setItem('auth_type', value);
  },

  setCurrentUser(user) {
    currentUser = user;
  },

  setFormAuthenticityToken(token) {
    formAuthenticityToken = token
  },

  getFormAuthenticityToken() {
    return formAuthenticityToken
  },

  async tryCurrentUser() {
    if (currentUser) return currentUser
    let response = await api.get('current_user')
    return response.data?.currentUser
  },

  navigationItems(route) {
    return [
      { name: 'My Gift Events', routerLink: '/app.html', current: route.path.includes("/gift-event") || route.path.includes("/app.html") },
      { name: 'Private Gift Lists', routerLink: '/gift-lists', current: route.path.includes("/gift-lists") },
      { name: 'Support', routerLink: '/support', current: route.path.includes("/support") },
    ]
  },

  async getCurrentUser() {
    if (currentUser) return currentUser
    let response = await api.get('current_user')
    const user = response.data?.currentUser
    if (!user) return
    this.setCurrentUser(user)
    this.setBearerToken(response.data.bearerToken)
    this.setAuthType(response.data.authType)
    this.setFormAuthenticityToken(response.data.formAuthenticityToken)
    return currentUser
  },

  async assertCurrentUser() {
    let response = await api.get('current_user')
    const user = response.data?.currentUser
    if (!user) {
      if (this.getAuthType() === 'sso') {
        window.location = `${process.env.VUE_APP_API_URL.replace('https://api.togethergiftit.com/api', 'https://api.togethergiftit.com')}/?redirect_to=${encodeURIComponent(window.location.href)}`
      } else {
        window.location = '/'
      }
      return
    }
    this.setCurrentUser(user)
    this.setBearerToken(response.data.bearerToken)
    this.setAuthType(response.data.authType)
    this.setFormAuthenticityToken(response.data.formAuthenticityToken)
    return currentUser
  }

};