<template>
  <div>
    <svg
      :fill="starred || product?.star ? 'gold' : 'white'"
      stroke="gray"
      stroke-width="1"
      class="w-8 h-8 cursor-pointer"
      viewBox="0 0 24 24"
      @click="toggleStar"
    >
      <path d="M12 .288l2.833 8.718H24l-7.08 5.145 2.832 8.719L12 17.712l-7.08 5.158 2.832-8.719L0 9.006h9.167z" />
    </svg>

    <svg @click="trashIt" class="mt-8 w-8 h-8 cursor-pointer text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <!-- Lid -->
      <path d="M4 5h16M7 5V3h10v2M9 9v8M12 9v8M15 9v8" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            fill="none"/>
      
      <!-- Main body -->
      <path d="M5 5h14l-1.4 14H6.4L5 5z" 
            stroke="currentColor" 
            stroke-width="2" 
            fill="none"/>
    </svg>
    
  </div>
</template>

<script>
import api from '@/api';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    productSearch: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      starred: false
    };
  },
  watch: {
    product: {
      handler() {
        this.starred = this.product.star;
      },
      immediate: true
    }
  },
  methods: {
    async toggleStar() {
      const newStarredStatus = !this.product.star;
      this.starred = newStarredStatus;
      await api.updateProductRecommendationPref(this.productSearch, this.product.slug, { star: newStarredStatus });
      this.$emit('product-star-change', { ...this.product, star: newStarredStatus });
    },
    async trashIt() {
      await api.updateProductRecommendationPref(this.productSearch, this.product.slug, { trash: true });
      this.$emit('product-star-change', { ...this.product, trash: true });
    }
  }
};
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
