<template>
  <div v-if="initialized && (!currentUser || (currentUser && currentUser?.giftBotCredits > 0))" class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
    <form class="space-y-6" @submit.prevent="submitForm">
        <div class="px-4 pb-4">
          <h1 class="text-center pb-4">
            How picky should GiftBot be?
          </h1>
          <RadioGroup v-model="exactitude">
            <RadioGroupLabel class="sr-only">Purchase Choices</RadioGroupLabel>
            <div class="-space-y-px rounded-md bg-white">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in exactitudes" :key="setting.name" :value="setting" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === products.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-pink-50 border-pink-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-pink-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-pink-500' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-pink-900' : 'text-gray-900', 'block text-sm font-medium']">{{ setting.name }}</RadioGroupLabel>
                    <RadioGroupDescription as="span" :class="[checked ? 'text-pink-700' : 'text-gray-500', 'block text-sm']">{{ setting.description }}</RadioGroupDescription>
                  </span>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>

          <div class="px-4 pt-12 pb-8">
            <h1 class="text-center text-lg pb-4">Price Range</h1>
            <div class="slider-container">
              <div class="slider-track"></div>

              <!-- Min Price Label -->
              <div
                class="slider-value min-value"
                :style="{ left: ((minPrice / maxSliderValue) * 100) + '%' }"
              >
                ${{ minPrice }}
              </div>

              <!-- Max Price Label -->
              <div
                class="slider-value max-value"
                :style="{ left: ((maxPrice / maxSliderValue) * 100) + '%' }"
              >
                ${{ maxPrice }}
              </div>

              <!-- Sliders -->
              <input
                type="range"
                v-model.number="minPrice"
                :min="0"
                :max="maxSliderValue"
                step="1"
                @input="onMinPriceChange"
                class="slider-thumb min-thumb"
              />
              <input
                type="range"
                v-model.number="maxPrice"
                :min="0"
                :max="maxSliderValue"
                step="1"
                @input="onMaxPriceChange"
                class="slider-thumb max-thumb"
              />
            </div>
            <div class="flex items-center space-x-2 mt-4">
              <div class="relative w-full hidden">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500">$</span>
                </div>
                <input
                  type="number"
                  size="4"
                  v-model.number="minPrice"
                  placeholder="Min Price"
                  class="w-full border-gray-300 rounded-md pl-7"
                />
              </div>
              <div class="relative w-full hidden">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500">$</span>
                </div>
                <input
                  type="number"
                  size="4"
                  v-model.number="maxPrice"
                  placeholder="Max Price"
                  class="w-full border-gray-300 rounded-md pl-7"
                />
              </div>
            </div>
          </div>
        
          <!-- Product Condition -->
          <div class="pb-4 pt-8">
            <h1 class="text-center text-lg">Product Condition</h1>
            <div class="grid grid-cols-3 gap-4">
              <label class="flex items-center space-x-1">
                <input type="radio" v-model="productCondition" value="new" /> <span>New</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="radio" v-model="productCondition" value="used" /> <span>Used</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="radio" v-model="productCondition" value="refurbished" /> <span>Refurbished</span>
              </label>
            </div>
          </div>

          <!-- Stores -->
          <div class="pb-4 pt-8">
            <h1 class="text-center text-lg">Stores</h1>
            <div class="grid grid-cols-3 gap-4">
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.bestBuy" /> <span>Best Buy</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.walmart" /> <span>Walmart</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.amazon" /> <span>Amazon</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.target" /> <span>Target</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.etsy" /> <span>Etsy</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="stores.ebay" /> <span>eBay</span>
              </label>
            </div>
          </div>

          <!-- Free Shipping, On Sale, Free Returns -->
          <div class="pb-4 pt-8">
            <div class="grid grid-cols-3 gap-4">
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="freeShipping" /> <span>Free Shipping</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="onSale" /> <span>On Sale</span>
              </label>
              <label class="flex items-center space-x-1">
                <input type="checkbox" v-model="freeReturns" /> <span>Free Returns</span>
              </label>
            </div>
          </div>

          <div class="pb-4 pt-8">            
            <h1 class="text-center text-lg">Additional Instructions</h1>
            <textarea rows="2" name="instructions" id="instructions" v-model="instructions" class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md" />
          </div>
          
          <button type="button" @click="activateBot()" class="mx-auto flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-500 text-base font-medium text-white hover:bg-pink-600 sm:text-sm">
            Activate GiftBot
          </button>
        </div>

        <div class="text-center" v-if="currentUser">
          <h1 class="font-bold text-center text-gray-600 pb-4">
            You have {{ currentUser.giftBotCredits }} Together GiftBot credits remaining.<br />
            <br />
            Each activation uses 1 credit.
          </h1>
        </div>
      </form>
  </div>

  <div v-if="currentUser?.giftBotCredits <= 0" class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <h1 class="font-bold text-center pb-4">
        Purchase credits to use Together GiftBot
      </h1>
      <form class="space-y-6" @submit.prevent="submitForm">
        <div>
          <RadioGroup v-model="product">
            <RadioGroupLabel class="sr-only">Purchase Choices</RadioGroupLabel>
            <div class="-space-y-px rounded-md bg-white">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in products" :key="setting.name" :value="setting" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === products.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-pink-50 border-pink-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-pink-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-pink-500' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-pink-900' : 'text-gray-900', 'block text-sm font-medium']">{{ setting.name }}</RadioGroupLabel>
                    <RadioGroupDescription as="span" :class="[checked ? 'text-pink-700' : 'text-gray-500', 'block text-sm']">{{ setting.description }}</RadioGroupDescription>
                  </span>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
        <div>
          <button @click="startPurchase()" type="button" class="flex w-full justify-center rounded-md border border-transparent bg-pink-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
            PURCHASE NOW
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import store from '@/store'

export default {
  components: {
    RadioGroup, 
    RadioGroupDescription, 
    RadioGroupLabel, 
    RadioGroupOption,
  },

  props: {
  },

  emits: ['activateGiftBot'],

  async mounted() {
    this.currentUser = await store.getCurrentUser()
    this.products = [
      { name: '10 credits for $1', description: "You get Together GiftBot's undivided attention. 10 times.", value: '10' },
      { name: '75 credits for $5', description: "Together GiftBot saves the day. 75 times.", value: '75' },
      { name: '200 credits for $10', description: "For those who expect a little more from life. Together GiftBot has you covered. 200 times.", value: '200' },
    ]
    this.product = this.products[1]
    this.initialized = true  
  },

  data () {
    let defaultSpecificity = { name: "Medium Pickiness",  value: "medium",  description: "GiftBot will select products that closely match the gift idea." }
    return {
      initialized: false,
      open: false,
      products: null,
      product: null,
      currentUser: null,
      instructions: null,
      exactitude: defaultSpecificity,
      exactitudes: [
        { name: "Very Picky", value: "very",  description: "GiftBot will only select products that exactly match the gift idea." },
        defaultSpecificity,
        { name: "Not Picky", value: "not_very", description: "GiftBot may improvise when selecting products." },
      ],
      minPrice: 0,
      maxPrice: 1000,
      maxSliderValue: 1000,
      priceRange: [this.minPrice, this.maxPrice],
      productCondition: 'new',
      stores: {
        bestBuy: true,
        walmart: true,
        amazon: true,
        target: true,
        etsy: true,
        ebay: true,
      },
      freeShipping: false,
      onSale: false,
      freeReturns: false,
    }
  },

  watch: {
    priceRange(newVal) {
      this.minPrice = newVal[0]
      this.maxPrice = newVal[1]
    },
    minPrice(newVal) {
      if (newVal === null || newVal === undefined || newVal === '') {
        this.minPrice = 0;
      }
    },
    maxPrice(newVal) {
      if (newVal === null || newVal === undefined || newVal === '') {
        this.maxPrice = this.maxSliderValue;
      }
    },
  },

  methods: {
    async activateBot() {
      this.open = false
      this.$emit('activateGiftBot', {
        exactitude: this.exactitude.value,
        instructions: this.instructions,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        productCondition: this.productCondition,
        stores: this.stores,
        freeShipping: this.freeShipping,
        onSale: this.onSale,
        freeReturns: this.freeReturns,
      })
    },

    async startPurchase() {
      window.location = `${process.env.VUE_APP_API_URL}checkout?product=${this.product.value}&return_url=${encodeURIComponent(window.location.href)}`
    },

    onMinPriceChange() {
      if (this.minPrice > this.maxPrice) {
        this.minPrice = this.maxPrice
      }
    },
    onMaxPriceChange() {
      if (this.maxPrice < this.minPrice) {
        this.maxPrice = this.minPrice
      }
    },
  }  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider-container {
  position: relative;
  width: 100%;
  height: 30px;
}

.slider-track {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ddd;
  top: 50%;
  transform: translateY(-50%);
}

.slider-thumb {
  top: 4px;
  position: absolute;
  width: 100%;
  pointer-events: none;
  background: none;
  -webkit-appearance: none;
}

.slider-thumb::-webkit-slider-thumb {
  pointer-events: all;
  width: 20px;
  height: 20px;
  background-color: #f9fafb; /* gray-50 */
  border: 2px solid #f472b6; /* pink-500 */
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.slider-thumb::-moz-range-thumb {
  pointer-events: all;
  width: 20px;
  height: 20px;
  background-color: #f9fafb; /* gray-50 */
  border: 2px solid #f472b6; /* pink-500 */
  border-radius: 50%;
  cursor: pointer;
}

.min-thumb {
  z-index: 3;
}

.max-thumb {
  z-index: 2;
}

.min-thumb::-webkit-slider-thumb {
  transform: translateX(-10px);
}

.max-thumb::-webkit-slider-thumb {
  transform: translateX(10px);
}

.slider-value {
  position: absolute;
  top: -25px;
  transform: translateX(-50%);
  /* Add styling as needed, e.g., font size, color */
}
</style>
