import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import Rollbar from 'rollbar';
import mitt from 'mitt'
import './assets/tailwind.css'
import { createGtm } from '@gtm-support/vue-gtm';
import cookieService from '@/cookieService';

const emitter = mitt()
const app = createApp(App);

app.config.globalProperties.emitter = emitter

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
app.config.globalProperties.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
// eslint-disable-next-line no-unused-vars
app.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err)
  console.log(err)
  console.log(info)
  throw err // rethrow
};

app.use(router);

let enableGtm = cookieService.getConsentCookie()?.toString() === "true"
app.use(
  createGtm({
    id: `${process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID}`, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    /*
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: 'xyz',
      gtm_preview: 'env-4',
      gtm_cookies_win: 'x',
    },
    */
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26f', // Will add `nonce` to the script tag
    enabled: enableGtm, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
);

app.mount('#app');
