import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import store from '@/store'

const DEFAULT_TITLE = 'Together Gift It';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about.html',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - About` },
  },
  {
    path: '/blog.html',
    name: 'Blog',
    // route level code-splitting
    // this generates a separate chunk (blog.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blog" */ '../views/BlogPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Blog` },
  },
  {
    path: '/blog/stress-free-holidays-with-together-gift-it.html',
    name: 'BlogStressFreeHolidaysPage',
    component: () => import(/* webpackChunkName: "BlogStressFreeHolidaysPage" */ '../views/BlogStressFreeHolidaysPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Stress-free Holidays with Together Gift It` },
  },
  {
    path: '/blog/together-gift-it-gift-lists.html',
    name: 'BlogGiftListsPage',
    component: () => import(/* webpackChunkName: "BlogGiftListsPage" */ '../views/BlogGiftListsPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Together Gift It Gift Lists` },
  },
  {
    path: '/blog/together-gift-bot.html',
    name: 'BlogTogetherGiftBotPage',
    component: () => import(/* webpackChunkName: "BlogTogetherGiftBotPage" */ '../views/BlogTogetherGiftBotPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Together GiftBot` },
  },
  {
    path: '/privacy.html',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Privacy` },
  },
  {
    path: '/app.html',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Dashboard` },
  },
  {
    path: '/gift-event/:giftEventSlug',
    name: 'GiftEventDashboardPage',
    component: () => import(/* webpackChunkName: "GiftEventDashboardPage" */ '../views/GiftEventDashboardPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Gift Event Dashboard` },
  },
  {
    path: '/signup.html',
    name: 'NewUserPage',
    component: () => import(/* webpackChunkName: "NewUserPage" */ '../views/NewUserPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Welcome!` },
  },
  {
    path: '/gift-lists',
    name: 'GiftListDashboardPage',
    component: () => import(/* webpackChunkName: "GiftListDashboardPage" */ '../views/GiftListDashboardPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Gift List Dashboard` },
  },
  {
    path: '/gift-lists/recipient/:giftRecipientSlug',
    name: 'GiftListRecipientPage',
    component: () => import(/* webpackChunkName: "GiftListRecipientPage" */ '../views/GiftListRecipientPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Gift List Recipient` },
  },
  {
    path: '/gift-lists/recipient/:giftRecipientSlug/gift-bot',
    name: 'GiftListGiftBotPage',
    component: () => import(/* webpackChunkName: "GiftListGiftBotPage" */ '../views/GiftListGiftBotPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Gift List Recipient GiftBot` },
  },
  {
    path: '/gift-event/:giftEventSlug/recipient/:recipientSlug',
    name: 'RecipientDashboard',
    component: () => import(/* webpackChunkName: "RecipientDashboardPage" */ '../views/RecipientDashboardPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Recipient Dashboard` },
  },
  {
    path: '/gift-event/:giftEventSlug/recipient/:recipientSlug/gift-bot',
    name: 'RecipientGiftBotPage',
    component: () => import(/* webpackChunkName: "RecipientGiftBotPage" */ '../views/RecipientGiftBotPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Event Recipient GiftBot` },
  },
  {
    path: '/gift-event/:giftEventSlug/purchases',
    name: 'GiftEventPurchasesPage',
    component: () => import(/* webpackChunkName: "GiftEventPurchasesPage" */ '../views/GiftEventPurchasesPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Purchases` },
  },
  {
    path: '/gift-event/:giftEventSlug/settings',
    name: 'GiftEventSettingsPage',
    component: () => import(/* webpackChunkName: "GiftEventSettingsPage" */ '../views/GiftEventSettingsPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Event Settings` },
  },
  {
    path: '/gift-event/:slug/invite',
    name: 'GiftEventAddUserPage',
    component: () => import(/* webpackChunkName: "GiftEventAddUserPage" */ '../views/GiftEventAddUserPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Add User` },
  },
  {
    path: '/new-gift-event',
    name: 'NewGiftEventPage',
    component: () => import(/* webpackChunkName: "NewGiftEventPage" */ '../views/NewGiftEventPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - New Event` },
  },
  {
    path: '/support',
    name: 'SupportPage',
    component: () => import(/* webpackChunkName: "SupportPage" */ '../views/SupportPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Support` },
  },
  {
    path: '/terms.html',
    name: 'TermsOfUsePage',
    component: () => import(/* webpackChunkName: "SupportPage" */ '../views/TermsOfUsePage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Terms of Use` },
  },
  {
    path: '/error.html',
    name: 'ErrorPage',
    component: () => import(/* webpackChunkName: "ErrorPage" */ '../views/ErrorPage.vue'),
    meta: { title: `${DEFAULT_TITLE} - Error` },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // eslint-disable-next-line
  scrollBehavior: function (to, from, savedPosition) {
    return { top: 0 }
  },
  routes,
})

// eslint-disable-next-line
router.afterEach((to, from) => {
  if (to.path == '/') return
  if (to.path == '/privacy.html') return
  if (to.path == '/terms.html') return
  if (to.path == '/404.html') return
  if (to.path == '/error.html') return
  if (to.path == '/about.html') return
  if (to.path.startsWith('/blog')) return
  if (to.path.startsWith('/signup')) return
  store.assertCurrentUser()
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router
